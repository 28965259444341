import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Button, Input, message} from 'antd';

function ChatRoom() {
    const [messages, setMessages] = useState([]);
    const [nameInput, setNameInput] = useState('');
    const [contentInput, setContentInput] = useState('');

    const {TextArea} = Input;
    const proxy = "https://chat-room-api.kisstaiyang.space";

    useEffect(() => {
        const intervalId = setInterval(refreshMessages, 1000); // 每隔1秒刷新消息
        return () => clearInterval(intervalId); // 组件卸载时清除定时器
    }, []);

    const refreshMessages = () => {
        axios.get(proxy + '/messages')
            .then(response => {
                setMessages(response.data);
            })
            .catch(error => {
                console.error('Error getting messages:', error);
            });
    };

    const handleNameInputChange = (event) => {
        setNameInput(event.target.value);
    };

    const handleContentInputChange = (event) => {
        setContentInput(event.target.value);
    };

    const handleSendMessage = () => {
        if (nameInput.trim() === '') {
            message.error('姓名不能为空');
            return;
        }

        if (contentInput.trim() === '') {
            message.error('内容不能为空');
            return;
        }

        if (nameInput.trim() !== '' && contentInput.trim() !== '') {
            axios.post(proxy + '/send-message', {name: nameInput, content: contentInput})
                .then(() => {
                    setNameInput('');
                    setContentInput('');
                })
                .catch(error => {
                    console.error('Error sending message:', error);
                });
        }
    };

    return (
        <div style={{display: 'flex', flexDirection: 'column', height: '100vh'}}>
            <div id="chat-container" style={{flex: 1, overflow: 'auto'}}>
                {messages.map((message, index) => (
                    <div key={index} className="message">
                        <strong>{message.name}: </strong> {message.content}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        {message.created_at}
                    </div>
                ))}
            </div>

            <div id="input-container"
                 style={{position: 'fixed', bottom: 30, left: 0, right: 0, padding: '16px', background: '#fff'}}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                    <Input
                        placeholder="姓名"
                        value={nameInput}
                        onChange={handleNameInputChange}
                        style={{width: '120px'}}
                    />
                </div>
                <div style={{margin: '12px 0'}}/>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                    <TextArea
                        placeholder="内容"
                        value={contentInput}
                        onChange={handleContentInputChange}
                        autoSize={{minRows: 3, maxRows: 5}}
                        style={{width: '480px'}}
                        showCount
                        maxLength={280}
                    />
                </div>
                <Button type="primary" onClick={handleSendMessage}
                        style={{marginTop: '24px', marginLeft: '420px'}}>发言</Button>
            </div>
        </div>
    );
}

export default ChatRoom;
