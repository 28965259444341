import React from 'react';
import ChatRoom from './ChatRoom';

function App() {
    return (
        <div className="App">
            <h1>GGBond is my boy God, he is not a dead pig!!!</h1>
            <ChatRoom/>
        </div>
    );
}

export default App;

